import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=25ca3133&scoped=true"
import script from "./NavBar.vue?vue&type=script&lang=js"
export * from "./NavBar.vue?vue&type=script&lang=js"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=25ca3133&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ca3133",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3MobileMenuDrawer: require('/var/app/components/Theme3/Mobile/MenuDrawer.vue').default,Theme3Announcement: require('/var/app/components/Theme3/Announcement.vue').default,Theme3MobileExchangeRateModal: require('/var/app/components/Theme3/Mobile/ExchangeRateModal.vue').default,Theme3DesktopLanguageSwitcher: require('/var/app/components/Theme3/Desktop/LanguageSwitcher.vue').default,CommonsThemeMode: require('/var/app/components/Commons/ThemeMode.vue').default})
