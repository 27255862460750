import { render, staticRenderFns } from "./CustomFooter.vue?vue&type=template&id=a10dd4fe&scoped=true"
import script from "./CustomFooter.vue?vue&type=script&lang=js"
export * from "./CustomFooter.vue?vue&type=script&lang=js"
import style0 from "./CustomFooter.vue?vue&type=style&index=0&id=a10dd4fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a10dd4fe",
  null
  
)

export default component.exports